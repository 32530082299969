import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AdminMain from "./pages/Admin_home";
import Intro from "./components/Admin/Intro";
import NotFound from "./components/Admin/PageError";
import AdminBoard from "./pages/Admin_board";
import { useCookies } from "react-cookie";

const SCGSRouter = createBrowserRouter([
  {
    path: "/",
    errorElement: <NotFound />,
    element: <Intro />,
  },
  ...[
    "/news",
    "/disclose",
    "/recruit",
    "/building",
    // "/inquiry",
    "/quote",
    "/notice",
  ].map((path) => ({
    path: `${path}/:id`,
    element: <AdminBoard />,
  })),
  ...[
    "home",
    "news",
    "disclose",
    "recruit",
    "building",
    // "inquiry",
    "quote",
    "notice",
  ].map((path) => ({
    path: `/${path}`,
    element: <AdminMain />,
  })),
]);

/* 24.10.23 "inquiry", 숨김 */ 
const SCGSRouterTh = createBrowserRouter([
  {
    path: "/",
    errorElement: <NotFound />,
    element: <Intro />,
  },
  ...["/news", "/disclose", "/recruit", "/quote", "/notice"].map(
    (path) => ({
      path: `${path}/:id`,
      element: <AdminBoard />,
    })
  ),
  ...["home", "news", "disclose", "recruit", "quote", "notice"].map(
    (path) => ({
      path: `/${path}`,
      element: <AdminMain />,
    })
  ),
]);

const Router = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["adminLoginToken"]);
  const parts = cookies.adminLoginToken && cookies.adminLoginToken.split(".");
  const data = cookies.adminLoginToken && JSON.parse(atob(parts[1])).data;
  const coockieData =
    cookies.adminLoginToken && JSON.parse(atob(parts[1])).data;

  return (
    <RouterProvider
      router={data && data.legionid === 1 ? SCGSRouter : SCGSRouterTh}
    />
  );
};

export default Router;
