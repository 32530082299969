import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

interface widthInterface {
  w?: number;
}
const navList = {
  ko: [
    { to: "홈", path: "home" },
    { to: "뉴스", path: "news" },
    { to: "전자공고", path: "disclose" },
    { to: "채용정보", path: "recruit" },
    { to: "건물종합관리", path: "building" },
    // { to: "1:1 문의", path: "inquiry" },
    { to: "견적 문의", path: "quote" },
    // { to: "공지 관리", path: "notice" },
  ],
  th: [
    { to: "홈", path: "home" },
    { to: "뉴스", path: "news" },
    { to: "전자공고", path: "disclose" },
    { to: "채용정보", path: "recruit" },
  ],
};
const HeadNavStyle = styled.ul<widthInterface>`
  display: flex;
  text-align: center;
  cursor: pointer;
  li {
    width: ${(props) => `calc(100vw / ${Number(props.w) + 1})`};
    transition: 0.3s;
    display: flex;
    height: 50px;
    position: relative;
    font-weight: 500;
    &.active {
      background: #026;
      color: #fff;
      &:hover {
        transition: 0.1s;
        background: #039;
      }
      &:active {
        transition: 0.1s;
        background: #013;
      }
    }
    img {
      vertical-align: bottom;
      margin-right: 10px;
    }
    p {
      margin: auto;
    }
    &:last-child {
      margin-right: 0px;
      width: 80px;
      height: 20px;
      margin: auto;
      font-size: 12px;
      border-radius: 10px;
      border: 1px solid #ccc;
    }
    &:hover {
      transition: 0.1s;
      background: #eee;
    }
    &:active {
      transition: 0.1s;
      background: #ddd;
    }
  }
  a {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`;

const HeadNav = ({ data, cookies, removeCookie }: any) => {
  const navigate = useNavigate();

  useEffect(() => {
    cookies.adminLoginToken ? <></> : navigate("/");
  }, [cookies.adminLoginToken, data]);
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const pathIdCheck = location.pathname.split("/")[2];
  return (
    <HeadNavStyle
      className="no-select"
      w={
        data?.legionid === 1 || 0
          ? navList.ko.length
          : data?.legionid === 2
          ? navList.th.length
          : 0
      }
    >
      {data?.legionid === 1 &&
        navList.ko.map((res, idx) => {
          return (
            <li key={idx} className={path === `${res.path}` ? "active" : ""}>
              <p>
                {res.to === "홈" &&
                  (path === `${res.path}` ? (
                    <img srcSet="/favi_white.svg" alt="logo" />
                  ) : (
                    <img srcSet="/favi_color.svg" alt="logo" />
                  ))}
                {res.to}
              </p>
              {path !== `${res.path}` || pathIdCheck ? (
                <Link to={{ pathname: `/${res.path}` }}></Link>
              ) : (
                <></>
              )}
            </li>
          );
        })}
      {data?.legionid === 2 &&
        navList.th.map((res, idx) => {
          return (
            <li key={idx} className={path === `${res.path}` ? "active" : ""}>
              <p>
                {res.to === "홈" &&
                  (path === `${res.path}` ? (
                    <img srcSet="/favi_white.svg" alt="logo" />
                  ) : (
                    <img srcSet="/favi_color.svg" alt="logo" />
                  ))}
                {res.to}
              </p>
              {path !== `${res.path}` || pathIdCheck ? (
                <Link to={{ pathname: `/${res.path}` }}></Link>
              ) : (
                <></>
              )}
            </li>
          );
        })}
      <li
        className="logout"
        onClick={() => {
          // 쿠키 삭제
          navigate("/");
          removeCookie("adminLoginToken");
        }}
      >
        <p>로그아웃</p>
      </li>
    </HeadNavStyle>
  );
};

export default HeadNav;
