const pathCheck = ({
  path,
  setSendPath,
}: {
  path: string;
  setSendPath: any;
}) => {
  switch (path) {
    case "quote":
      path === "quote" && setSendPath("Board_Quote");
      break;
    case "news":
      path === "news" && setSendPath("Board_News");
      break;
    case "disclose":
      path === "disclose" && setSendPath("Board_Announcement");
      break;
    case "notice":
      path === "notice" && setSendPath("Board_Notice");
      break;
    case "recruit":
      path === "recruit" && setSendPath("Board_Employment");
      break;
    case "building":
      path === "building" && setSendPath("Board_BuildingMng");
      break;

    default:
      break;
  }
};
// case "inquiry":
//   path === "inquiry" && setSendPath("Board_Inquiry");
//   break;
export default pathCheck;
